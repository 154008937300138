import React from "react";

/**
 * Paste in your SVG logo and return it from this component.
 * Make sure you have a height set for your logo.
 * It is recommended to keep the height within 25-35px.
 * Logo comes with a property vallue called `fill`. `fill` is useful
 * when you want to change your logo depending on the theme you are on.
 */
export default function Logo({ fill }) {
  return (
      <>
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="200" height="45" viewBox="190 0 1000.000000 300.000000"
 preserveAspectRatio="xMidYMid meet">
<metadata>
Neilblaze SVG Font
</metadata>
<g transform="translate(0.000000,300.000000) scale(0.100000,-0.100000)"
fill="#000000" stroke="none">
<path d="M320 1506 l0 -636 45 0 45 0 2 562 3 563 416 -560 415 -560 52 -3 52
-3 0 635 0 636 -45 0 -45 0 -2 -562 -3 -563 -416 560 -415 560 -52 3 -52 3 0
-635z" fill={fill}/>
<path d="M1570 2100 l0 -40 443 2 442 3 0 35 0 35 -442 3 -443 2 0 -40z" fill={fill}/>
<path d="M2630 1505 l0 -635 45 0 45 0 0 635 0 635 -45 0 -45 0 0 -635z" fill={fill}/>
<path d="M2900 1505 l0 -635 470 0 470 0 0 34 c0 22 -6 36 -16 40 -9 3 -200 6
-425 6 l-409 0 0 595 0 595 -45 0 -45 0 0 -635z" fill={fill}/>
<path d="M4000 1506 l0 -636 333 0 c368 0 425 7 534 60 113 56 163 141 163
279 0 110 -36 177 -132 250 -10 7 -43 23 -73 36 l-56 22 45 27 c102 59 152
157 144 280 -8 114 -64 195 -174 250 -103 52 -155 59 -481 63 l-303 5 0 -636z
m671 526 c134 -44 192 -114 193 -233 0 -53 -5 -74 -27 -111 -67 -114 -194
-148 -554 -148 l-193 0 0 261 0 261 258 -4 c229 -4 264 -7 323 -26z m93 -599
c107 -41 159 -100 172 -197 11 -75 -12 -143 -65 -195 -82 -81 -100 -85 -463
-89 l-318 -3 0 256 0 256 308 -3 c287 -3 311 -5 366 -25z" fill={fill}/>
<path d="M5210 1505 l0 -635 470 0 470 0 0 34 c0 22 -6 36 -16 40 -9 3 -200 6
-425 6 l-409 0 0 595 0 595 -45 0 -45 0 0 -635z" fill={fill}/>
<path d="M6525 1514 c-149 -344 -272 -631 -274 -636 -2 -5 20 -8 49 -6 l53 3
126 295 127 295 238 0 239 0 126 -295 127 -295 51 -3 52 -3 -272 633 -273 633
-50 3 -50 3 -269 -627z m420 271 c52 -121 95 -225 95 -232 0 -10 -45 -13 -200
-13 -110 0 -200 2 -200 3 0 2 41 98 91 213 50 115 95 220 100 233 5 13 12 22
14 19 3 -2 48 -103 100 -223z" fill={fill}/>
<path d="M7560 2100 l0 -40 456 -2 457 -3 -457 -555 c-422 -513 -456 -558
-456 -592 l0 -38 515 0 515 0 0 35 c0 19 -1 36 -2 37 -2 1 -206 5 -455 8
l-451 5 454 552 c434 527 454 554 454 592 l0 41 -515 0 -515 0 0 -40z" fill={fill}/>
<path d="M8790 2100 l0 -40 443 2 442 3 0 35 0 35 -442 3 -443 2 0 -40z" fill={fill}/>
<path d="M1570 1500 l0 -40 374 0 c206 0 381 3 390 6 10 4 16 18 16 40 l0 34
-390 0 -390 0 0 -40z" fill={fill}/>
<path d="M8790 1500 l0 -40 374 0 c206 0 381 3 390 6 10 4 16 18 16 40 l0 34
-390 0 -390 0 0 -40z" fill={fill}/>
<path d="M1583 943 c-7 -2 -13 -20 -13 -39 l0 -34 450 0 450 0 0 34 c0 22 -6
36 -16 40 -19 7 -853 7 -871 -1z" fill={fill}/>
<path d="M8803 943 c-7 -2 -13 -20 -13 -39 l0 -34 450 0 450 0 0 34 c0 22 -6
36 -16 40 -19 7 -853 7 -871 -1z" fill={fill}/>
</g>
</svg>
    </>
  );
}
